<template>
  <div class="works-center-bg">
    <div class="works-center-con">
      <b-container fluid>
        <h2 class="page-title">作品中心</h2>
        <waterfall :col="colCount" :data="masterpiecesList" class="solution">
          <template>
            <div class="solution-item" v-for="(item,index) in masterpiecesList" :key="index" @click="to2DDetail(item)">
              <div class="solution-item-ehome">
                <img class="solution-img" :src="item.coverUri" />
                <div class="solution-name ehome-solution-title-name">{{item.name}}</div>
                <div class="solution-user-box">
                  <img class="solution-user-avatar" :src="item.headImgUri"
                    v-if="item.headImgUri !='' && item.headImgUri !=null" />
                  <img class="solution-user-avatar" src="@/../public/img/person.431c96c3.png" v-else />
                  <div class="solution-designer-name ehome-solution-name"
                    v-if="item.nickName !=null && item.nickName != '' && item.nickName !='null'">
                    {{item.nickName}}
                  </div>
                  <div class="solution-designer-name ehome-solution-name" v-else>{{$t('portalTitle')}}
                  </div>
                  <i class="el-icon-view watch-icon"></i>
                  <div class="solution-watch-number ehome-solution-name">
                    {{item.viewNum==null?0:item.viewNum}}
                  </div>
                </div>
              </div>
            </div>
          </template>
        </waterfall>
      </b-container>
    </div>
  </div>
</template>

<script>
  import dump from "@/util/dump";
  export default {
    data (){
      return {
        token: null,
        colCount: 5,
        masterpiecesList: []
      }
    },
    created() {
      let width = document.body.clientWidth;
      if (width <= 800 && width > 400) {
        this.colCount = 2;
      } else if (width <= 400) {
        this.colCount = 1;
      }
      this.init();
    },
    methods: {
      init(){
        this.token = localStorage.getItem("token");
        this.getMyTwoDimension();
      },
      // 获取会员作品
      getMyTwoDimension(){
        this.$api.myTwoDimension({
          pageNum: 1,
          pageSize: 15,
          token: this.token
        }).then(res => {
          if(res.code == 200){
            this.masterpiecesList = res.data.list;
          }
        }).catch(err => console.error(err))
      },
      // 跳转到二维方案详情
      to2DDetail(item) {
        item.type = 1;
        item.link = "/twodimensionalprogramme";
        item.params = {
          id: item.id
        };
        dump.link(item);
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/style";
  @import "@/assets/scss/userCenterCommon";
  @import "@/view/userCenter/worksCenter/worksCenter";
</style>
